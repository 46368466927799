<template>
  <div>
    <div class="row mx-2 my-0" v-if="loading">
      <div
        class="
          col-12 col-md-10 col-lg-8
          offset-md-1 offset-lg-2
          card
          shadow-card
          p-0
        "
      >
        <alert class="m-0" />
      </div>
    </div>

    <div v-else class="row mx-2 my-0">
      <div
        class="
          col-12 col-md-10 col-lg-8
          offset-md-1 offset-lg-2
          card
          shadow-card
          py-5
          px-4
        "
      >
        <h1 class="mb-5">Edit Referral Source</h1>
        <form @submit.prevent="updateReferralSource" validate>
          <input-control
            type="horizontal"
            v-model.trim="referralSource.first"
            labelClass="col-12 col-md-3 col-lg-2"
            required
            >First Name</input-control
          >

          <input-control
            type="horizontal"
            v-model.trim="referralSource.last"
            labelClass="col-12 col-md-3 col-lg-2"
            required
            >Last Name</input-control
          >
          <save classes="mt-3 save-button btn btn-theme" :saving="saving"
            >Save</save
          >
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      referralSource: {},
    };
  },
  mounted() {
    this.getReferralSource({ id: this.referralSourceID }).then(() => {
      this.referralSource = JSON.parse(
        JSON.stringify(this.currentReferralSource)
      );
    });
  },
  computed: {
    ...mapState({
      currentReferralSource: (state) =>
        state.referralSources.currentReferralSource.data,
      loading: (state) => state.referralSources.currentReferralSource.loading,
      saving: (state) => state.referralSources.saving,
    }),
    referralSourceID: function () {
      return this.$route.params.id;
    },
  },
  methods: {
    ...mapActions({
      getReferralSource: "referralSources/getReferralSource",
      update: "referralSources/update",
    }),
    updateReferralSource: function () {
      this.update(this.referralSource);
    },
  },
};
</script>
